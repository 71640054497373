<template>
  <div class="batchBox">
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
      </div>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label">商品名称</template>
          {{detail.info.goods_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否上架</template>
          {{detail.info.status == 1?'是':'否'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">积分价格</template>
          {{detail.info.price}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建时间</template>
          {{detail.info.created_at}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品备注</template>
          {{detail.info.message}}
        </el-descriptions-item>
      </el-descriptions>
      <div class="title">
        <i class="el-icon-collection-tag" />内含物品
      </div>
      <el-row>
              <el-col :span="24">
                <el-table
                  :data="detail.object_list"
                  size="small"
                  border
                  :cell-style="$style.cellStyle"
                  :header-cell-style="$style.rowClass"
                >
                <ElTableColumn label="物品名称" prop="name" />
                <ElTableColumn label="数量"  prop="number" />
                <ElTableColumn label="物品种类"  prop="type_name" />
                <ElTableColumn label="天数/次数">
                  <template slot-scope="{ row }">
                    <div v-if="row.service_number != 0">{{row.service_number}}次</div>
                    <div v-if="row.service_days != 0">{{row.service_days}}天</div>
                    <div v-if="row.service_days == 0&&row.service_number==0">--</div>
                  </template>
                </ElTableColumn>
                </el-table>
              </el-col>
            </el-row>
      <el-col class="rightCon" :span="24">
          <el-form
            ref="detail"
            label-position="right"
            label-width="170px"
            :model="detail"
            class="formBox"
          >
            <el-form-item label="banner图上传:" >
              <el-upload
                action=""
                list-type="picture-card"
                :file-list="detail.banner"
                :on-preview="handlePictureCardPreview"
                :http-request="uploadFile1"
                :on-remove="handleRemove1">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="详情图上传:" >
              <el-upload
                action=""
                list-type="picture-card"
                :file-list="detail.image"
                :on-preview="handlePictureCardPreview"
                :http-request="uploadFile2"
                :on-remove="handleRemove2">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
           <div class="btnBox">
            <el-button
              type="primary"
              size="mini"
              class="mainBtn"
              @click="submitForm('detail')"
            >完成，提交商品</el-button>
            </div>
          </el-form>
        </el-col>

    </div>
  </div>
</template>

<script>
import { getPointGoodsDetail,uploadPointGoodsImage,editPointGoods } from '@/api/goods';
class Detail {
  info=[];
  banner=[];
  image=[];
  object_list=[];
}
export default {
  data () {
    return {
      detail:new Detail(),
      // banner_cannel:[],
      cancel:[],
      banner_new:[],
      image_new:[],
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail(){
       getPointGoodsDetail({id:this.$route.query.id} ).then(res => {
        this.detail = res.data;
      })
    },
    handleRemove1(file, fileList) {
      // console.log(this.detail.banner);
      const files = this.detail.banner;
      for(let i = 0 ;i<files.length;i++)
      {
        if(files[i].uid==file.uid)
        {
          this.cancel.push(file.id);
          break;
        }
      }
      const banner_new = this.banner_new;
      for(let i = 0 ;i<banner_new.length;i++)
      {
        if(banner_new[i].uid==file.uid)
        {
          banner_new.splice(i,1);
          break;
        }
      }
      this.banner_new=banner_new;

    },
    handleRemove2(file, fileList) {
      // console.log(this.detail.banner);
      const files = this.detail.image;
      for(let i = 0 ;i<files.length;i++)
      {
        if(files[i].uid==file.uid)
        {
          this.cancel.push(file.id);
          break;
        }
      }
      const image_new = this.image_new;
      for(let i = 0 ;i<image_new.length;i++)
      {
        if(image_new[i].uid==file.uid)
        {
          image_new.splice(i,1);
          break;
        }
      }
      this.image_new=image_new;

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadFile1(file,filelist){
      const files = this.banner_new;
      const formData = new FormData();
      formData.append("file", file.file);
      const uid = file.file.uid;
      // formData.append("type", 1);
      uploadPointGoodsImage(formData).then(res => {
        if(res.code == 200)
        {
          let data = {};
          data.url = res.data.url;
          data.type = 1;
          data.uid = uid;
          files.push(data);
          this.banner_new=files;
        }else{

        }
      });
      console.log(this.detail.files);
    },
    uploadFile2(file,filelist){
      const files = this.image_new;
      const formData = new FormData();
      formData.append("file", file.file);
      const uid = file.file.uid;
      console.log(uid);

      uploadPointGoodsImage(formData).then(res => {
        if(res.code == 200)
        {
          let data = {};
          data.url = res.data.url;
          data.type = 2;
          data.uid = uid;
          files.push(data);
          this.image_new=files;
        }else{

        }
      });
    },
    submitForm(){
      console.log(this.cancel);
      console.log(this.banner_new);
      console.log(this.image_new);
      editPointGoods({cancel:this.cancel,banner_new:this.banner_new,image_new:this.image_new,id:this.$route.query.id}).then(
      res=>{
        if(res.code == 200)
        {
          this.$message({
            message: '修改完成',
            type: 'success'
          });
          location.reload();
        }else{
          this.$message.error('修改失败');
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: #f9fafc;
}
.banner{
  width: 90%;
  margin: auto;
}
.banner-item{
  margin: 20px;
  width: 10%;
  height: 180px;
  padding: 20px;
  border: #666 solid 1px;
}
</style>
