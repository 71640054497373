import axios from '@/utils/axios'

// 商品列表
export const goodsList = params => axios({
    method: 'get',
    url: '/admin/goods/index',
    params
  }).then(res => res.data)
// 订单页商品列表
export const goodsList2 = params => axios({
    method: 'get',
    url: '/admin/goods/goodsList',
    params
  }).then(res => res.data)

// 商品详情
export const goodsGetail = params => axios({
  method: 'get',
  url: '/admin/goods/detail',
  params
}).then(res => res.data)

// 添加商品
export const createGoods = data =>
  axios.post('/admin/goods/create', data)
    .then(res => res)
    
// 获取服务小项
export const goodsControls = params => axios({
  method: 'get',
  url: '/admin/goods/getControls',
  params
}).then(res => res.data)

// 编辑商品
export const updateGoods = data =>
  axios.post('/admin/goods/update', data)
    .then(res => res)

// 商品导出
export const exportGoods = data =>
  axios.post('/admin/goods/export', data)
    .then(res => res)

// 删除商品
export const deleteGoods = data =>
  axios.post('/admin/goods/delete', data)
    .then(res => res)

// 商品分类列表
export const categoryList = params => axios({
  method: 'get',
  url: '/admin/category/index',
  params
}).then(res => res.data)

// 新增商品分类
export const createCategory = data =>
  axios.post('/admin/category/create', data)
    .then(res => res)

// 商品分类详情
export const categoryDetail = params => axios({
  method: 'get',
  url: '/admin/category/detail',
  params
}).then(res => res.data)

// 更新商品分类
export const updateCategory = data =>
  axios.post('/admin/category/update', data)
    .then(res => res)

// 商品详情
export const pointGoodsList = params => axios({
  method: 'get',
  url: '/mini/CustomerPoint/pointGoodsList',
  params
}).then(res => res)
// 上传积分商品图
export const uploadPointGoodsImage = data =>
  axios.post('/mini/CustomerPoint/uploadPointGoodsImage', data)
    .then(res => res)
// 新增商品分类
export const addPointGoods = data =>
  axios.post('/mini/CustomerPoint/addPointGoods', data)
    .then(res => res)
// 商品详情
export const getPointGoodsDetail = params => axios({
  method: 'get',
  url: '/mini/CustomerPoint/getPointGoodsDetail',
  params
}).then(res => res)
// 编辑
export const editPointGoods = data =>
  axios.post('/mini/CustomerPoint/editPointGoods', data)
    .then(res => res)
// 修改状态
export const pointGoodsStatusUpdate = data =>
  axios.post('/mini/CustomerPoint/pointGoodsStatusUpdate', data)
    .then(res => res)

// 品项分析排名列表
export const goodsRankingList = params => axios({
  method: 'get',
  url: '/admin/goods/goodsRankingList',
  params
}).then(res => res.data)
